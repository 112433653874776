body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Aktiv Grotesk";
  src: url("./assets/fonts/aktiv-grotesk/AktivGrotesk-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Ivar Headline Medium";
  src: url("./assets/fonts/ivar-headline/IvarHeadline-Medium.otf") format("otf");
}

@font-face {
  font-family: "Ivar Headline Medium";
  src: url("./assets/fonts/ivar-headline/IvarHeadline-Medium.woff") format("woff");
}

@font-face {
  font-family: "Ivar Headline Medium";
  src: url("./assets/fonts/ivar-headline/IvarHeadline-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Ivar Headline Regular";
  src: url("./assets/fonts/ivar-headline/IvarHeadline-Regular.otf") format("otf");
}

@font-face {
  font-family: "Ivar Headline Italic";
  src: url("./assets/fonts/ivar-headline/ivarheadline-italic-webfont.woff") format("woff");
}

@font-face {
  font-family: "Ivar Headline Italic";
  src: url("./assets/fonts/ivar-headline/ivarheadline-italic-webfont.woff2") format("woff2");
}

@font-face {
  font-family: "Ivar Headline Regular";
  src: url("./assets/fonts/ivar-headline/ivarheadline-regular-webfont.woff") format("woff");
}

@font-face {
  font-family: "Ivar Headline Regular";
  src: url("./assets/fonts/ivar-headline/ivarheadline-regular-webfont.woff2") format("woff2");
}
